import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';
import ModalVideo from 'react-modal-video';
import Countdown from 'react-countdown-now';
//import firebase from '../services/firebase';
import qs from 'qs';
import appScreenshot from '../assets/images/app-screenshot.png'
import pic1 from '../assets/images/1.png'
import pic2 from '../assets/images/2.png'
import pic3 from '../assets/images/3.png'
import yuda from '../assets/images/yuda.png'
import fikry from '../assets/images/fikry.png'
import irfan from '../assets/images/irfan.png'
import cover from '../assets/images/cover-2.png'
import phpid from '../assets/images/phpid.png'
import daengweb from '../assets/images/daengweb.png'
import melisa from '../assets/images/melisa.png'
import petanikode from '../assets/images/petanikode.png'
import devsurvey from '../assets/images/dev-survey.png'
import githubreact from '../assets/images/github-react.png'

import facebook from '../assets/images/facebook.svg'
import whatsapp from '../assets/images/whatsapp.svg'
import instagram from '../assets/images/instagram.svg'
import twitter from '../assets/images/twitter.svg'
import netflix from '../assets/images/netflix.svg'
import airbnb from '../assets/images/airbnb.svg'
import twitch from '../assets/images/twitch.svg'
import pinterest from '../assets/images/pinterest.svg'
import trello from '../assets/images/trello.svg'
import microsoft from '../assets/images/microsoft.svg'
function Homepage({ location }) {

    const siteTitle = "JagoReact.com - Video Tutorial React dan Firebase Terbaru Bahasa Indoesia";

    const [persistRef, setPersistRef] = useState('');
    const [modal, setModal] = useState(false);
    const [yearsExp] = useState(Math.floor((Date.now() - 1388941200000) / 3.154e+10));
    useEffect(() => {
        if (location.search) {
            const query = qs.parse(location.search, { ignoreQueryPrefix: true });
            if (query.ref) {
                setPersistRef(`https://member.jagoreact.com/persist-ref/${query.ref}`);
            }

        }
    }, [location.search])

    return (
        <Layout>
            <Helmet title={siteTitle} />

            <section id="one" className="main style1">
                <div className="grid-wrapper">
                    <div className="col-6">
                        <header className="major">
                            <h2>Studi Kasus</h2>
                        </header>
                        <p>Dalam Seri Video Tutorial ini, kamu akan belajar membuat sebuah <strong>Aplikasi Penjualan</strong> menggunakan fitur terbaru di <strong>React 16.8+</strong> yaitu <strong>React Hooks</strong> dan Database generasi terbaru dari Firebase yaitu <strong>Cloud Firestore</strong>.</p>
                        <ul className="actions uniform">
                            <li><a href="https://demo.jagoreact.com" target="_blank" rel="noopener noreferrer" className="button special">Demo Aplikasi</a></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <span className="image fit" ><img loading="lazy" src={appScreenshot} alt="app screenshot" /></span>
                    </div>
                </div>
            </section>
            {/* 
                <section id="two" className="main style2">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <ul className="major-icons">
                                <li><span className="icon style1 major fa-code"></span></li>
                                <li><span className="icon style2 major fa-bolt"></span></li>
                                <li><span className="icon style3 major fa-camera-retro"></span></li>
                                <li><span className="icon style4 major fa-cog"></span></li>
                                <li><span className="icon style5 major fa-desktop"></span></li>
                                <li><span className="icon style6 major fa-calendar"></span></li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <header className="major">
                                <h2>Lorem ipsum dolor adipiscing<br />
                                    amet dolor consequat</h2>
                            </header>
                            <p>Adipiscing a commodo ante nunc accumsan interdum mi ante adipiscing. A nunc lobortis non nisl amet vis volutpat aclacus nascetur ac non. Lorem curae eu ante amet sapien in tempus ac. Adipiscing id accumsan adipiscing ipsum.</p>
                            <p>Blandit faucibus proin. Ac aliquam integer adipiscing enim non praesent vis commodo nunc phasellus cubilia ac risus accumsan. Accumsan blandit. Lobortis phasellus non lobortis dit varius mi varius accumsan lobortis. Blandit ante aliquam lacinia lorem lobortis semper morbi col faucibus vitae integer placerat accumsan orci eu mi odio tempus adipiscing adipiscing adipiscing curae consequat feugiat etiam dolore.</p>
                            <p>Adipiscing a commodo ante nunc accumsan interdum mi ante adipiscing. A nunc lobortis non nisl amet vis volutpat aclacus nascetur ac non. Lorem curae eu ante amet sapien in tempus ac. Adipiscing id accumsan adipiscing ipsum.</p>
                        </div>
                    </div>
                </section> */}

            <section id="two" className="main style2 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>48 Video Yang Akan Kamu Pelajari</h2>
                        </header>
                        <ul className="actions uniform">
                            <li><a href="#" onClick={(e) => {
                                e.preventDefault();
                                setModal(true);
                            }} className="button special order-btn"><i className="fa fa-play"></i> Tonton Intro Video</a></li>
                        </ul>
                        {/* <div>
                            <div class='embed-container'>
                                <iframe src="https://www.youtube.com/embed/9FcJYngUHso" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div> */}

                        <p>Video tutorial ini akan dibagi menjadi 3 sesi yaitu:</p>
                    </div>

                    <div className="col-4">
                        <span className="image fit"><img loading="lazy" src={pic1} alt="" /></span>
                        <h3>Berkenalan Dengan React</h3>
                        <p>Pada sesi ini terdapat 7 video, kamu akan berkenalan dengan <strong>CodeSandbox.io, JSX, React Element, React Component, Props, state (useState hook), side effects (useEffect hook), Conditional Rendering,</strong> dan sebagainya. </p>
                        {/* <ul className="actions">
                                <li><a href="#" className="button">More</a></li>
                            </ul> */}
                    </div>
                    <div className="col-4">
                        <span className="image fit"><img loading="lazy" src={pic2} alt="" /></span>
                        <h3>Berkenalan Dengan Firebase</h3>
                        <p>Pada sesi ini terdapat 6 video, kamu akan berkenal dengan Firebase Console untuk <strong>Membuat Proyek Firebase dan Mengaktifkan fitur Authentication, Cloud Firestore, dan Cloud Storage. Selain itu, kamu juga akan berkenalan dengan Firebase SDK untuk Web dan Firebase CLI</strong> .</p>
                        {/* <ul className="actions">
                                <li><a href="#" className="button">More</a></li>
                            </ul> */}
                    </div>
                    <div className="col-4">
                        <span className="image fit"><img loading="lazy" src={pic3} alt="" /></span>
                        <h3>Membuat Proyek Aplikasi</h3>
                        <p>Pada sesi ini terdapat 34 video, kamu akan mengenal Arsitektur <strong>JAMStack dan SPA</strong>, mendefinisikan Fitur dan Halaman dari Aplikasi, membuat proyek SPA menggunakan <strong>Create React App</strong>, menggunakan fitur <strong>React Context dan Hooks</strong>, menggunakan library seperti <strong>Material UI, React Router DOM, React Firebase Hooks dan banyak lagi</strong>. Selain itu, kamu akan belajar bagaimana cara  <strong>mendeploy aplikasi ke Netlify</strong> melalui<strong> GitHub repo</strong>.</p>
                        {/* <ul className="actions">
                                <li><a href="#" className="button">More</a></li>
                            </ul> */}
                    </div>


                </div>
            </section>
            <section id="about-creator" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Tentang Creator</h2>
                        </header>
                    </div>
                    <div className="col-4 align-center">
                        <img src={yuda} loading="lazy" className="avatar" alt="app screenshot" />
                    </div>
                    <div className="col-8 align-left avatar-details">
                        <h3>Yuda Sukmana</h3>
                        <p>Adalah seorang full-stack developer dengan lebih dari {yearsExp} tahun pengalaman dalam mengembangkan aplikasi web. Beberapa portofolio yang pernah dikerjakan diantaranya: <a href="https://sociocaster.com" target="_blank" rel="noopener noreferrer nofollow" >sociocaster.com</a>, <a href="https://melisa.id" target="_blank" rel="noopener noreferrer nofollow" >melisa.id</a>, dll.</p>
                    </div>

                </div>
            </section>
            <section id="testimoni" className="main style2 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Testimoni</h2>
                        </header>
                    </div>
                    <div className="col-6">
                        <img src={fikry} loading="lazy" className="avatar-testimoni" alt="app screenshot" />
                        <h4>Fikry Akhmad Haykal  </h4>
                        <p>
                            "Belajar React.js menjadi lebih mudah dengan mengikuti course ini, kelebihannya adalah video dengan arahan yang mudah diterima dan mendetail juga adanya grup chat untuk tanya jawab mengenai masalah yang dihadapi sangat membantu dalam proses pembelajaran."
                        </p>

                    </div>
                    <div className="col-6">
                        <img src={irfan} loading="lazy" className="avatar-testimoni" alt="app screenshot" />
                        <h4>Irfan Abdurahman</h4>
                        <p>

                            "Course nya sangat bagus dan mudah dipahami. Dengan video-video yang detail pembahasannya, sangat cocok untuk pemula maupun developer karena mempelajari React.js dari awal."
                        </p>


                    </div>

                </div>
            </section>
            {/* <section id="faq" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-6">
                        <header className="major">
                            <h2>Studi Kasus</h2>
                        </header>
                        <p>Dalam Seri Video Tutorial Premium ini, kamu akan belajar membuat sebuah <strong>Aplikasi Penjualan</strong> menggunakan fitur terbaru di <strong>React 16.8+</strong> yaitu <strong>React Hooks</strong> dan Database generasi terbaru dari Firebase yaitu <strong>Cloud Firestore</strong>.</p>
                        <ul className="actions uniform">
                            <li><a href="https://demo.jagoreact.com" target="_blank" rel="noopener noreferrer" className="button special">Demo Aplikasi</a></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <header className="major">
                            <h2>Studi Kasus</h2>
                        </header>
                        <p>Dalam Seri Video Tutorial Premium ini, kamu akan belajar membuat sebuah <strong>Aplikasi Penjualan</strong> menggunakan fitur terbaru di <strong>React 16.8+</strong> yaitu <strong>React Hooks</strong> dan Database generasi terbaru dari Firebase yaitu <strong>Cloud Firestore</strong>.</p>
                        <ul className="actions uniform">
                            <li><a href="https://demo.jagoreact.com" target="_blank" rel="noopener noreferrer" className="button special">Demo Aplikasi</a></li>
                        </ul>
                    </div>
                </div>
            </section> */}
            <section id="ekosistem" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>React Ekosistem</h2>
                        </header>
                        <p>React merupakan JavaScript UI (User Interface) library yang sangat populer bagi para pengembang web di seluruh dunia!</p>
                    </div>
                    <div className="col-6">
                        <p>
                            <a href="https://insights.stackoverflow.com/survey/2019#technology-_-most-loved-dreaded-and-wanted-web-frameworks" target="_blank" rel="noopener noreferrer nofollow">
                                <span className="image fit" > <img src={devsurvey} loading="lazy" alt="app screenshot" /></span>
                            </a>
                            <br />
                            Menurut hasil developer survey StackOverflow tahun 2019, React menduduki peringkat pertama dari kategori Most Loved and Wanted Web Frameworks.
                        </p>
                    </div>
                    <div className="col-6 ">
                        <p>
                            <a href="https://github.com/facebook/react" target="_blank" rel="noopener noreferrer nofollow">
                                <span className="image fit" > <img src={githubreact} loading="lazy" alt="app screenshot" /></span>
                            </a>
                            <br />
                            Berdasarkan data dari GitHub, React telah digunakan oleh lebih dari 2 juta repository, dan memiliki stars lebih dari 130ribu. React juga dipelihara oleh lebih dari 1300 kontributor yang berasal dari Facebook dan komunitas yang terdiri dari perusahaan dan individu.
                        </p>
                    </div>

                </div>
            </section>
            <section id="big-companies" className="main style2 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Web Besar Pengguna React</h2>
                        </header>
                    </div>
                    <div className="col-12">
                        <ul className="icons big-companies-icons">
                            <li>
                                <img src={facebook} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Facebook</p>
                            </li>
                            <li>
                                <img src={whatsapp} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Whatsapp</p>
                            </li>
                            <li>
                                <img src={instagram} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Instagram</p>
                            </li>

                            <li>
                                <img src={twitter} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Twitter</p>
                            </li>
                            <li>
                                <img src={airbnb} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Airbnb</p>
                            </li>
                            <li>
                                <img src={netflix} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Netflix</p>
                            </li>

                            <li>
                                <img src={twitch} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Twitch</p>
                            </li>
                            <li>
                                <img src={microsoft} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Microsoft</p>
                            </li>
                            <li>
                                <img src={trello} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Trello</p>
                            </li>

                            <li>
                                <img src={pinterest} loading="lazy" className="big-companies" alt="app screenshot" />
                                <p>Pinterest</p>
                            </li>


                        </ul>
                        <p>Dan Banyak Lagi...</p>

                    </div>


                </div>
            </section>
            <section id="download" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Download </h2>
                        </header>
                        <p>Dapatkan akses download video tutorial JagoReact.com sekarang juga!</p>
                    </div>
                    <div className="col-6">
                        <span className="image fit" ><img loading="lazy" src={cover} alt="app screenshot" /></span>
                    </div>
                    <div className="col-6 ">
                        <ul className="fa-ul align-left">
                            <li><span className="fa-li" ><i className="fa fa-download"></i></span>Akses Download 47 Video dan Source Code</li>
                            <li><span className="fa-li" ><i className="fa fa-telegram"></i></span>Akses Ke Private Telegram Group</li>
                            <li><span className="fa-li" ><i className="fa fa-user"></i></span>Lisensi Untuk 1 Orang</li>
                            <li><span className="fa-li" ><i className="fa fa-refresh"></i></span>Lifetime Update</li>
                        </ul>



                        <Countdown
                            date={'Mon, 1 Jun 2020 00:00:00'}
                            renderer={({ completed, days, hours, minutes, seconds }) => {
                                if (completed) {
                                    return <p>
                                        <span className="diskon">Diskon 65%</span>
                                        <br />
                                        <br />
                                        Harga Normal:<br />
                                        <span className="harga-normal"><strike>Rp450.000</strike></span>
                                        <br />
                                        &#8681;
                                    <br />
                                        Setelah Diskon:<br />
                                        <span className="harga-diskon">Rp157.500</span>
                                    </p>;
                                }
                                return <>
                                    <p>
                                        <span className="diskon">Diskon Akhir Tahun 70%</span>
                                        <br />
                                        <br />
                                        Harga Normal:<br />
                                        <span className="harga-normal"><strike>Rp450.000</strike></span>
                                        <br />
                                        &#8681;
                            <br />
                                        Setelah Diskon:<br />
                                        <span className="harga-diskon">Rp135.000</span>
                                    </p>
                                    <p>
                                        Penawaran berakhir dalam:<br />
                                        <span className="countdown">{days}</span><small> hari</small> <span className="countdown">{hours}</span><small> jam</small> <span className="countdown">{minutes}</span><small> menit</small>  <span className="countdown">{seconds}</span ><small> detik</small></p>
                                </>
                            }} />
                    </div>
                    <div className="col-12">
                        <ul className="actions uniform">
                            <li><a href="https://member.jagoreact.com/signup" className="button special order-btn"> <i className="fa fa-shopping-cart"></i>{` Order Sekarang`}</a></li>
                        </ul>
                    </div>

                    {/* 
                    <ul className="actions uniform">
                        <li>
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="my@email.com" />
                        </li>
                        <li><button onClick={submitEmail} className="button special">Beritahu Saya Lewat Email</button></li>
                    </ul> */}
                </div>

            </section>
            <section id="faq" className="main style2 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>FAQ</h2>
                        </header>
                    </div>
                    <div className="col-6">

                        <h4>Untuk siapa video tutorial ini?</h4>
                        <p>
                            Video tutorial ini ditujukan untuk siapa saja yang tertarik untuk belajar membuat aplikasi web modern dengan React dan Firebase, dengan syarat memiliki pemahaman dasar tentang: HTML, CSS (Flexbox), JavaScript (ES6 dan ESNext)
                        </p>

                    </div>
                    <div className="col-6">

                        <h4>Dimana saya bisa mendownload video tutorial ini?</h4>
                        <p>
                            Video tutorial ini dapat diakses di member area <a href="https://member.jagoreact.com/download">https://member.jagoreact.com</a>, dan hanya pengguna yang sudah terkonfirmasi melakukan order dan pembayaran yang dapat mengaksesnya.
                        </p>



                    </div>
                    <div className="col-6">

                        <h4>Berapa total ukuran seluruh file video yang akan didownload?</h4>
                        <p>
                            Video tutorial ini memiliki ukuran file lebih dari <strong>6.5GB</strong>, pastikan anda memiliki koneksi yang stabil untuk mendownload file tersebut.
                            </p>



                    </div>
                    <div className="col-6">

                        <h4>Berapa total durasi video tutorial ini?</h4>
                        <p>
                            Video tutorial ini berdurasi sekitar <strong>8.5</strong> jam, setiap part video berdurasi antara 4 sampai 24 menit.
                        </p>



                    </div>
                    <div className="col-12">

                        <h4>Pertanyaan lainnya?</h4>
                        <p>
                            Silakan hubungi tim JagoReact.com melalui email: <a href="mailto:info@jagoreact.com" target="_blank" rel="noopener noreferrer nofollow">info@jagoreact.com</a>/whatsapp:<a href="https://wa.me/6289653023212" target="_blank" rel="noopener noreferrer nofollow">089653023212</a>.
                        </p>



                    </div>

                </div>
            </section>

            <section id="partner" className="main style1 special">
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Official Partner</h2>
                        </header>
                    </div>
                    <div className="col-3">
                        <p>
                            <a href="https://www.phpindonesia.id/" target="_blank" rel="noopener noreferrer nofollow">
                                <img src={phpid} loading="lazy" className="partner-logo" alt="app screenshot" />
                            </a>
                        </p>
                    </div>
                    <div className="col-3">
                        <p>
                            <a href="https://daengweb.id/" target="_blank" rel="noopener noreferrer nofollow">
                                <img src={daengweb} loading="lazy" className="partner-logo" alt="app screenshot" />
                            </a>
                        </p>
                    </div>
                    <div className="col-3">
                        <p><a href="https://melisa.id/" target="_blank" rel="noopener noreferrer nofollow">
                            <img src={melisa} loading="lazy" className="partner-logo" alt="app screenshot" />
                        </a></p>
                    </div>
                    <div className="col-3">
                        <p><a href="https://www.petanikode.com/" target="_blank" rel="noopener noreferrer nofollow">
                            <img src={petanikode} loading="lazy" className="partner-logo" style={{ maxWidth: 120 }} alt="app screenshot" />
                        </a></p>
                    </div>
                </div>
            </section>
            {
                persistRef && <iframe src={persistRef} style={{ width: 0, height: 0, border: 0 }}></iframe>
            }
            <ModalVideo channel='youtube' isOpen={modal} videoId='NDWWoiEmJVU' onClose={() => setModal(false)} autoplay="1" />
        </Layout>

    );

}

export default Homepage;